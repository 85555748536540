import React from "react"
import { Heading } from "@chakra-ui/core"

import SEO from "../components/SEO"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <Heading>404</Heading>
    </>
  )
}

export default NotFoundPage
